import React, { useState } from 'react';
import withBg from '../helpers/withBg';
import Layout from '../components/layout';
import SEO from '../components/seo';
import TrustpilotHorizontal from '../components/TrustpilotHorizontal/index';
import SidebarStaticPage from '../components/Sidebar/SidebarStaticPage';

import './styles.css';

const Cars = ({ phoneNumber }) => {
  const data = {
    title: 'Search Rental Car Deals',
    seoTitle: 'Search Rental Car Deals | Travelopod',
    seoDescription:
      'Find and book the cheapest flights to any destination at Travelopod! Save money on unique deals for flights, hotels and car rentals online or call us 24/7 for live booking assistance.'
  };
  const [phoneNum, setTest] = useState({ phoneNumber });

  const description = data.seoDescription;
  const seotitle = data.seoTitle;

  return (
    <Layout
      isStaticPage
      passPhoneNumber={phoneNum => {
        setTest({ phoneNumber: phoneNum });
      }}
    >
      <SEO title={seotitle} description={description} />
      <div className="main c-content-page">
        <div className="page-content">
          <div className="flight-content">
            <div className="text-block">
              <h1>Search Rental Car Deals</h1>
              <iframe
                title="widget-cars"
                src="https://secure.rentalcars.com/partners/integrations/stand-alone-us/?affiliateCode=travelopod&preflang=en&custom=true&fts=true"
                height="400px"
                width="100%"
                frameBorder="0"
                scrolling="no"
              />
            </div>
          </div>
          <SidebarStaticPage phoneNumber={phoneNumber} />
        </div>
      </div>
      <TrustpilotHorizontal />
    </Layout>
  );
};

export default withBg(Cars);
